.customAlert {
  display: flex;
  justify-content: center;
  position: sticky;
  z-index: 999999 !important;

  & > .ant-modal-content {
    padding: 24px 24px 16px 24px;
    border-radius: 8px;
    width: fit-content;
    min-width: 259px;

    & .ant-modal-body {
      width: 100%;

      & .ant-modal-confirm-body {
        display: flex;
        gap: 16px;

        & .ant-modal-confirm-title {
          font-family: Pretendard;
          font-weight: 700;
          font-size: 14px;
          line-height: 17px;
        }
        & .ant-modal-confirm-content {
          margin-block-start: 0px;
          font-family: Pretendard;
          font-weight: 400;
          font-size: 13px;
          line-height: 19.5px;
        }
      }

      & .ant-modal-confirm-btns {
        display: flex;
        gap: 8px;
        flex-direction: row;
        justify-content: flex-end;
        & button {
          padding: 8px 16px;
          height: 100%;
          font-family: Pretendard;
          font-weight: 400;
          font-size: 13px;
          line-height: 15.6px;
          border-radius: 4px;

          //   width: 100%;
        }
        & .ant-btn-default {
          border: 1px solid #999;
        }
        & .ant-btn-primary {
          margin-inline-start: 0px;
          background-color: #6c62d1;
        }
      }
    }
  }
}

.MuiTooltip-popper {
  .MuiTooltip-tooltip {
    background: #ff0;
    opacity: 1;
    border-radius: 2;
    padding: 16px;
  }
}

//select

.MuiInputBase-root {
  .MuiSelect-select {
    padding: 8px;
    padding-right: 8px !important;
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: #999;
  }
}
.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #6c62d1 !important;
}

#__next[aria-hidden='true'] {
  .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #6c62d1;
    border-bottom: 0px;
    border-radius: 4px 4px 0px 0px;
  }
}

.Mui-disabled {
  background: #eaeaea !important;
  .MuiOutlinedInput-notchedOutline {
    border-color: #999 !important;
  }
}

.MuiOutlinedInput-root {
  margin: 0px;
  width: 100%;
  min-width: 130px;
  height: 32px;
  background: #fff;
}
.MuiPopover-root {
  .MuiPaper-root {
    box-shadow: none;
    border: 1px solid #6c62d1;
    border-top: 0px;
    border-radius: 0px 0px 4px 4px;
    max-height: 150px !important;
    // margin-left : 0.5px;

    .MuiList-root {
      .MuiButtonBase-root {
        padding: 8px 6px !important;
      }
    }
  }
}

//  .ant-select-open{

.selectComp {
  background: #fff;
}

.ant-select:hover {
  .ant-select-selector {
    border: 1px solid #6c62d1 !important;

    .ant-select-selection-item {
      color: #222;
    }
  }

  .ant-select-arrow > span {
    color: #222;
  }
}
.ant-select-selector {
  border-radius: 4px !important;
  border: 1px solid #999 !important;
  transition: none !important;
  box-shadow: none !important;
  .ant-select-selection-item {
    color: #999;
  }
}

//  }

.ant-select-open {
  .ant-select-selector {
    border: 1px solid #6c62d1 !important;
    border-radius: 4px 4px 0px 0px !important;
    border-bottom: 0px !important;
    &:hover {
      border-bottom: 0px !important;
    }
    .ant-select-selection-item {
      color: #222;
    }
  }

  .ant-select-arrow {
    transform: rotate(180deg);
    color: #222;
  }
}

.ant-select-dropdown {
  border-radius: 0px 0px 4px 4px;
  border: 1px solid #6c62d1;
  border-top: 0px;
  top: calc(100% - 5px);

  .ant-select-item-option-selected,
  .ant-select-item-option-active {
    background-color: transparent !important;
  }

  .ant-select-selection-item,
  .ant-select-item-option-content {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    font-family: Pretendard;
  }
}
